import React from "react";

import {section as sectionClass, overwrite, grid, wrapper, titleLine, markdown, itemIcon} from './person-skills.module.scss';

import {ISection} from "../../models/section.model";
import {IPeople} from "../../models/people.model";

import Section from "../hoc/section";
import Markdown from "../hoc/markdown";
import Title from "../atoms/title";

import SkillIcon from '../../assets/images/svg/certificate.svg';


export interface IPersonSkillsSection extends ISection {
    extendedItems: {
        person: IPeople;
    };
}

interface IPersonSkillsProps {
    className?: string;
    section: IPersonSkillsSection;
    TitleTag?: React.ElementType;
}

const PersonSkills: React.FC<IPersonSkillsProps> = ({ className = '', section, TitleTag = 'h1' }) => {
    const { person } = section.extendedItems;
    const { sectionId, css, style } = section;

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionClass} ${overwrite}`}
            classes={{
                container: grid,
            }}
            css={css}
            style={style}
        >
            <div className={wrapper}>
                <Title className={titleLine}>
                    Kwalifikacje zawodowe
                </Title>
                <Markdown className={markdown} components={{li: ({children}) => (<li><SkillIcon className={itemIcon} />{children}</li>)}}>
                    {person.qualifications}
                </Markdown>
            </div>
        </Section>
    )
}

export default PersonSkills;