import React from "react";

import {section as sectionClass, grid, wrapper, serviceButton} from './person-services.module.scss';

import {ISection} from "../../models/section.model";
import {IPeople} from "../../models/people.model";

import Section from "../hoc/section";
import Button from "../atoms/button";


export interface IPersonServicesSection extends ISection {
    extendedItems: {
        person: IPeople;
    };
}

interface IPersonServicesProps {
    className?: string;
    section: IPersonServicesSection;
    TitleTag?: React.ElementType;
}

const PersonServices: React.FC<IPersonServicesProps> = ({ className = '', section, TitleTag = 'h1' }) => {
    const { person } = section.extendedItems;
    const { sectionId, css, style } = section;

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionClass}`}
            classes={{
                container: grid,
            }}
            css={css}
            style={style}
        >
            <div className={wrapper}>
                {person.services.map((service) => {
                    return (
                        <Button className={serviceButton} key={`service-${service.serviceId}`} to={service.pathname} stylePreset={'secondary'} as={'link'}>
                            {service.name}
                        </Button>
                    )
                })}
            </div>
        </Section>
    )
}

export default PersonServices;