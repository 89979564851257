import SectionHeader from '../components/sections/header';
import SectionBreadcrumbs from '../components/sections/breadcrumbs';
import SectionPersonPreview from '../components/sections/person-preview';
import SectionPersonServices from '../components/sections/person-services';
import SectionPersonDescription from '../components/sections/person-description';
import SectionPersonSkills from '../components/sections/person-skills';
import SectionPlain from '../components/sections/plain';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql } from 'gatsby';

import { IPage } from '../models/page.model';
import { IPeople } from '../models/people.model';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';

interface IPersonProps {
    readonly data: {
        page: IPage;
        people: IPeople;
    };
}

const Person: React.FC<IPersonProps> = ({ data }) => {
    const { page, people } = data;
    const sections = page.sections;
    const { mainSectionIndex } = getPageConfigFromSections(sections);

    const sectionsWithData = sections.map((section) => {
        if (
            ['person-preview', 'person-services', 'person-description', 'person-skills'].includes(
                section.type
            )
        ) {
            return {
                ...section,
                extendedItems: {
                    person: people,
                },
            };
        }
        if (['plain'].includes(section.type)) {
            const rest =
                people.phone && !people.appointmentUrl
                    ? `<a href="tel:${people.phone}">${people.phone}</a>`
                    : section.content.texts[2];

            return {
                ...section,
                content: {
                    ...section.content,
                    texts: [
                        '',
                        '',
                        `<p><a href="mailto:${people.email}">${people.email}</a></p>${rest}`,
                    ],
                    buttons: people.appointmentUrl
                        ? [
                              {
                                  url: people.appointmentUrl,
                                  text: section.content.buttons[0]?.text,
                              },
                          ]
                        : [],
                },
            };
        }
        return section;
    });

    return (
        <MainLayout>
            {sectionsWithData.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h4'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $personId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }) {
            sections {
                ...sectionFields
            }
        }
        people(personId: { eq: $personId }, locale: { eq: $locale }) {
            ...peopleFullFields
        }
    }
`;

export default Person;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'breadcrumbs': SectionBreadcrumbs,
    'person-preview': SectionPersonPreview,
    'person-services': SectionPersonServices,
    'person-description': SectionPersonDescription,
    'person-skills': SectionPersonSkills,
    'plain': SectionPlain,
    'footer': SectionFooter,
};