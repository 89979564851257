import React, {useState} from "react";

import {grid, wrapper, titleLine, markdown, closedMarkdown, openButton} from './person-description.module.scss';

import {ISection} from "../../models/section.model";
import {IPeople} from "../../models/people.model";

import Section from "../hoc/section";
import Markdown from "../hoc/markdown";
import Title from "../atoms/title";

export interface IPersonDescriptionSection extends ISection {
    extendedItems: {
        person: IPeople;
    };
}

interface IPersonDescriptionProps {
    className?: string;
    section: IPersonDescriptionSection;
    TitleTag?: React.ElementType;
}

const PersonDescription: React.FC<IPersonDescriptionProps> = ({ className = '', section, TitleTag = 'h1' }) => {
    const { person } = section.extendedItems;
    const { sectionId, css, style } = section;

    const [open, setOpen] = useState(false);

    const changeOpenState = () => {
        setOpen((open) => !open);
    }

    return (
        <Section
            sectionId={sectionId}
            className={`${className}`}
            classes={{
                container: grid,
            }}
            css={css}
            style={style}
        >
            <div className={wrapper}>
                <Title className={titleLine}>
                    O mnie
                </Title>
                <Markdown className={`${markdown} ${!open ? closedMarkdown : ''}`}>
                    {person.about}
                </Markdown>
                <button className={openButton} onClick={changeOpenState}>{open ? 'Schowaj' : 'Czytaj wiecej'}</button>
            </div>
        </Section>
    )
}

export default PersonDescription;