import React from "react";

import {scoreBox} from './score.module.scss';

import StarIcon from '../../assets/images/svg/star.svg';

interface IScoreProps {
    className?: string;
    score: string;
}

const Score: React.FC<IScoreProps> = ({className = '', score}) => {
    return (
        <p className={`${scoreBox} ${className}`}>
            <StarIcon />
            <span>{score}</span>
        </p>
    )
}

export default Score;