// extracted by mini-css-extract-plugin
export var contentWrapper = "person-preview-module--content-wrapper--bc8a1";
export var grid = "person-preview-module--grid--f32f8";
export var imageBox = "person-preview-module--image-box--c1a4f";
export var imageRatio = "person-preview-module--image-ratio--0ce9a";
export var imageWrapper = "person-preview-module--image-wrapper--01af2";
export var nameLine = "person-preview-module--name-line--a6982";
export var overwrite = "person-preview-module--overwrite--2690a";
export var section = "person-preview-module--section--032df";
export var specLine = "person-preview-module--spec-line--3d0ad";
export var visitButton = "person-preview-module--visit-button--37add";
export var wrapper = "person-preview-module--wrapper--2fa6a";