import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    section as sectionClass,
    overwrite,
    grid,
    wrapper,
    imageWrapper,
    contentWrapper,
    imageBox,
    imageRatio,
    scoreBox,
    nameLine,
    specLine,
    descMarkdown,
    visitButton,
} from './person-preview.module.scss';

import { ISection } from '../../models/section.model';
import { IPeople } from '../../models/people.model';

import Button from '../atoms/button';
import Section from '../hoc/section';
import Markdown from '../hoc/markdown';
import Score from '../molecules/score';

export interface IPersonPreviewSection extends ISection {
    extendedItems: {
        person: IPeople;
    };
}

interface IPersonPreviewProps {
    className?: string;
    section: IPersonPreviewSection;
    TitleTag?: React.ElementType;
}

const PersonPreview: React.FC<IPersonPreviewProps> = ({
    className = '',
    section,
    TitleTag = 'h1',
}) => {
    const { person } = section.extendedItems;
    const { sectionId, css, style } = section;

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionClass} ${overwrite}`}
            classes={{
                container: grid,
            }}
            css={css}
            style={style}
        >
            <div className={wrapper}>
                <div className={imageWrapper}>
                    <Image className={imageBox} ratioClass={imageRatio} media={person.media} />
                </div>
                <div className={contentWrapper}>
                    <Score className={scoreBox} score={person.rank} />
                    <p
                        className={nameLine}
                    >{`${person.title} ${person.firstName} ${person.lastName}`}</p>
                    <p className={specLine}>{person.specialization}</p>
                    <Markdown className={descMarkdown}>{person.description}</Markdown>
                    {person.appointmentUrl && (
                        <Button
                            stylePreset={'main'}
                            className={visitButton}
                            target={'_blank'}
                            href={person.appointmentUrl}
                            as={'externalLink'}
                        >
                            UMÓW SIĘ DO TEGO SPECJALISTY
                        </Button>
                    )}
                </div>
            </div>
        </Section>
    );
};

export default PersonPreview;
